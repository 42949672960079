import { createSharedComposable } from '@vueuse/core';

const _useUIState = () => {
  const isHeaderDialogOpen = ref(false);
  const isDashboardSidebarSlideoverOpen = ref(false);
  const isDashboardSearchModalOpen = ref(false);

  const toggleDashboardSearch = () => {
    if (isDashboardSidebarSlideoverOpen.value) {
      isDashboardSidebarSlideoverOpen.value = false;

      setTimeout(() => {
        isDashboardSearchModalOpen.value = !isDashboardSearchModalOpen.value;
      }, 0);

      return;
    }

    isDashboardSearchModalOpen.value = !isDashboardSearchModalOpen.value;

    consoleInfo('Dashboard search modal is open:', isDashboardSearchModalOpen.value);
  };

  return {
    isHeaderDialogOpen,
    isDashboardSearchModalOpen,
    toggleDashboardSearch,
  };
};

export const useUIState = createSharedComposable(_useUIState);
