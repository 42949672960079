<script setup lang="ts">
  import { Icon } from '@iconify/vue';
  import { cn } from '@/lib/utils';
  import type { LinkProp } from '~/types/settings';
  import {
    Tooltip,
    TooltipProvider,
    TooltipContent,
    TooltipTrigger,
  } from '@/components/ui/tooltip';
  import { buttonVariants } from '@/components/ui/button';

  const { $hasPermission } = useNuxtApp();

  defineProps<{
    isCollapsed?: boolean;
    links?: LinkProp[];
  }>();

  const can = (permission: string) => {
    if (typeof $hasPermission !== 'function') {
      return false;
    }
    return $hasPermission(permission);
  };
</script>

<template>
  <aside
    :data-collapsed="isCollapsed"
    class="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex"
  >
    <nav class="flex flex-col items-center gap-4 px-2 py-4">
      <NuxtImg src="/img/logo.png" alt="F&B 3.0" class="h-9 w-9 md:h-9 md:w-9" />

      <template v-for="(link, index) in links" :key="index">
        <TooltipProvider v-if="isCollapsed">
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <NuxtLink
                v-if="can(link.can)"
                :to="link.href"
                class="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
              >
                <Icon :icon="link.icon" class="h-5 w-5" />
                <span class="sr-only">{{ link.title }}</span>
              </NuxtLink>
            </TooltipTrigger>
            <TooltipContent side="right"> {{ link.title }}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <NuxtLink
          v-else
          :key="`2-${index}`"
          to="#"
          :class="
            cn(
              buttonVariants({ variant: link.variant, size: 'sm' }),
              link.variant === 'default' &&
                'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white',
              'justify-start'
            )
          "
        >
          <Icon :icon="link.icon" class="mr-2 size-4" />
          {{ link.title }}
          <span
            v-if="link.label"
            :class="cn('ml-auto', link.variant === 'default' && 'text-background dark:text-white')"
          >
            {{ link.label }}
          </span>
        </NuxtLink>
      </template>
      <div class="relative">
        
      </div>
    </nav>
    <nav class="mt-auto flex flex-col items-center gap-4 px-2 py-4">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <NuxtLink
              v-if="$hasPermission('settings.index')"
              to="/settings"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
            >
              <Icon icon="lucide:settings" class="h-5 w-5" />
              <span class="sr-only">Settings</span>
            </NuxtLink>
          </TooltipTrigger>
          <TooltipContent side="right"> Settings</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </nav>
  </aside>
</template>

<style scoped></style>
