<script setup lang="ts">
  const appConfig = useAppConfig();
  const { toggleDashboardSearch } = useUIState();
  const { metaSymbol } = useShortcuts();

  defineProps({
    label: {
      type: String,
      default: 'Search...',
    },
  });
</script>

<template>
  <UButton
    :icon="appConfig.ui.icons.search"
    :label="label"
    truncate
    v-bind="(!!label ? $ui?.button?.input : $ui?.button?.secondary) as any"
    aria-label="Search"
    :class="[!!label && 'flex-1 md:w-[200px] lg:w-[520px] 2xl:w-[620px]']"
    @click.prevent="toggleDashboardSearch"
  >
    <template v-if="!!label" #trailing>
      <div class="-my-1 ml-auto hidden flex-shrink-0 items-center gap-0.5 lg:flex">
        <UKbd>
          {{ metaSymbol }}
        </UKbd>
        <UKbd> K </UKbd>
      </div>
    </template>
  </UButton>
</template>
